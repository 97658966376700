import { useMutation, useQuery } from '@tanstack/react-query'

import AuthService from '@/shared/services/auth/auth.service'
import { IUsers } from '@/shared/types/user.types'

export const useResetPassword = () => {
	return useMutation(['reset password'], (data: IUsers.IResetPasswordForm) =>
		AuthService.resetPassword(data)
	)
}

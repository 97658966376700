import { eachYearOfInterval, endOfYear, getYear, startOfYear } from 'date-fns'
import { FC, useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Dropdown } from '../../dropdown'
import { MyOptionType } from '../form.interface'
import { ReactSelect } from '../select-react'

import { ICustomHeader } from './calendar.interface'
import { ReactComponent as ArrowLeftIcon } from '@/Assets/svg/arrow-left.svg'
import { ReactComponent as ArrowRightIcon } from '@/Assets/svg/arrow-right.svg'
import { useTranslationField } from '@/shared/hooks'
import { TValuesLocality } from '@/shared/types/global.types'

const months = [
	'january',
	'february',
	'march',
	'april',
	'may',
	'june',
	'july',
	'august',
	'september',
	'october',
	'november',
	'december'
]

const CustomHeader: FC<ICustomHeader> = ({
	date,
	decreaseMonth,
	increaseMonth,
	changeYear,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled
}) => {
	const { getFieldStatic } = useTranslationField()

	const currentYear = getYear(new Date())
	const startYear = 1900
	const startOfYearDate = startOfYear(new Date(startYear, 0, 1))
	const endOfYearDate = endOfYear(new Date(currentYear, 0, 1))
	const [yearOptions, setYearOptions] = useState<MyOptionType[]>([])

	useEffect(() => {
		const yearsArray = eachYearOfInterval({
			start: startOfYearDate,
			end: endOfYearDate
		})
			.map(year => ({
				label: getYear(year).toString(),
				value: getYear(year).toString()
			}))
			.reverse()
		setYearOptions(yearsArray)
	}, [])

	const onChangeYear = (item: MyOptionType) => {
		changeYear(+item.value)
	}
	return (
		<div className='custom-header-datepicker'>
			<div style={{ display: 'flex' }}>
				<span>
					{getFieldStatic(months[date.getMonth()] as TValuesLocality)}
				</span>
				<Dropdown
					options={yearOptions}
					defaultValue={date.getFullYear().toString()}
					onSelect={onChangeYear}
				/>
			</div>
			<div className='custom-header__arrows'>

			<button
				type='button'
				onClick={decreaseMonth}
				disabled={prevMonthButtonDisabled}
			>
				<ArrowLeftIcon className='arrow-icon-datepicker' />
			</button>

			<button
				type='button'
				onClick={increaseMonth}
				disabled={nextMonthButtonDisabled}
			>
				<ArrowRightIcon className='arrow-icon-datepicker' />
			</button>
			</div>

		</div>
	)
}

export default CustomHeader

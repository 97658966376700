import { FilterBlock } from '../filter-block'
import { GameList } from '../games-list'
import { GamesSliderCategory } from '../games-slider-category'

import styles from './GamesCatalog.module.scss'

export const GamesCatalog = () => {
	return (
		<div className={styles.wrapper}>
			{/* <GamesSliderCategory />
			<FilterBlock /> */}
			<GameList />
		</div>
	)
}

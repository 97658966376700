import { useAppSelector } from '@/shared/model'

/*
 * @param {object} obj - Объект с переводами.
 * @param {string} rawKey - Ключ для перевода.
 * @return {string} - Переведенное значение.
 */
export const useLocaleTranslation = (
	obj: Record<string, any>,
	rawKey: string
): any => {
	const { locale } = useAppSelector(state => state.defaultReducer)

	// Пробуем найти перевод в формате "ключ_локаль" (например, "title_ru")
	if (obj[rawKey + '_' + locale] !== undefined) {
		return obj[rawKey + '_' + locale]
	}

	// Если перевод в формате "ключ_локаль" не найден, пробуем найти перевод в формате "локальКлюч" (например, "ruTitle")
	return obj[locale + rawKey[0].toUpperCase() + rawKey.slice(1)]
}

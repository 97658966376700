// Библиотечные компоненты
import { FC } from 'react'

import styles from './NavMenu.module.scss'
import { NavMenuItem } from '@/entities/header'
import { LangChanger } from '@/features/header/ui'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import { IUsers } from '@/shared/types/user.types'

interface IProps {
	dataTabMenu: Array<LinkItemMenuProps>
	userData?: IUsers.UserRating
}

export const NavMenu: FC<IProps> = ({ dataTabMenu, userData }) => {
	return (
		<>
			<div className={styles.wrapper}>
				{dataTabMenu.map((item_menu, index) => {
					if (item_menu.link === '/lang')
						return (
							<div style={{ marginLeft: 'auto', marginRight: '1rem' }}>
								<LangChanger />
							</div>
						)

					return (
						<NavMenuItem
							key={item_menu.link}
							data={item_menu}
							// index={index}
							userData={userData}
						/>
					)
				})}
			</div>
		</>
	)
}

import { PropsWithChildren } from 'react'

export const modListModal = {
	simple: false,
	small: false,
	medium: false,
	zeroOffset: false,
	light: false,
	blur: false,
	absolute: false,
	deposit: false,
	inherit: false,
	tiny: false
}

export type TModalModifers = keyof typeof modListModal

export type TModal = PropsWithChildren & {
	className?: string
	isOpen: boolean
	onClose: () => void
	title?: string
	modifers?: TModalModifers[]
	disabledPortal?: boolean
	maxWidth?: number
	background?: string
	disabledClickOutside?: boolean
}

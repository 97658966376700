import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useAlertMessage } from '@/shared/hooks/useAlertMessage'
import SportService from '@/shared/services/sport.service'
import { useBasket } from '@/widgets/sports/bets-basket/useBasket'

function extractId(inputString: string) {
	// Регулярное выражение для захвата ID
	const regex = /([A-Z0-9]{20,})/
	const match = inputString?.match(regex)

	// Если найдено совпадение, вернуть ID, иначе вернуть null
	return match ? match[1] : null
}

export const useExpressBet = (markets: string[]) => {
	const { addAlertMessage } = useAlertMessage()
	const queryClient = useQueryClient()
	const cart = useBasket()

	return useMutation(['bet express', markets], (amount: number) =>
		SportService.betExpress(markets, amount)
			.then(() => {
				localStorage.setItem('cart', JSON.stringify([]))
				queryClient.invalidateQueries(['get user data'])
			})
			.catch(e => {

				const message = e.response?.data?.detail
				const gameIdMatch = message?.match(/gameID\s*(\d+)/)
				const marketId = extractId(message)
				const gameId = gameIdMatch ? parseInt(gameIdMatch[1]) : null

				if (e.response?.status === 400) {
					const newCart = cart.filter(
						item => item.main_const_sport_event_id !== gameId
					)
					localStorage.setItem('cart', JSON.stringify(newCart))
				}

				if (
					e.response.status === 404 &&
					message?.includes('Not Found or empty market')
				) {
					const newCart = cart.filter(item => item.market.pk !== marketId)

					localStorage.setItem('cart', JSON.stringify(newCart))
				}
				addAlertMessage(message, 'error')
			})
			.finally(() => {
				window.dispatchEvent(new Event('storage'))
			})
	)
}

import { FAQ } from '../constants'
import { TLocale, typeAction, typeDefaultState } from '../types'

import { LOCALES } from '@/i18n/locales'
import { API_URL } from '@/shared/api/config/api.config'

function support_format_webp() {
	let elem = document.createElement('canvas')
	if (!(elem.getContext && elem.getContext('2d'))) {
		// was able or not to get WebP representation
		return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0
	} else {
		// very old browser like IE 8, canvas not supported
		return false
	}
}

function supports_video() {
	return !!document.createElement('video').canPlayType
}

function support_format_webm() {
	if (!supports_video()) {
		return false
	}
	let v = document.createElement('video')
	return v.canPlayType('video/webm; codecs="vp9, vorbis"') === 'probably'
}

function defaultLocale() {
	const locale = localStorage.getItem('locale') as TLocale | null
	if (locale) {
		return locale
	} else {
		localStorage.setItem('locale', LOCALES.CHINESE)
		return LOCALES.CHINESE as TLocale
	}
}

const defaultState: typeDefaultState = {
	faq: FAQ,
	support_webP: support_format_webp(),
	support_webM: support_format_webm(),
	urlBackend: API_URL,
	locale: (function () {
		return defaultLocale()
	})(),
	loader: false,
	updateMarket: []
}

export default function defaultReducer(
	state = defaultState,
	action: typeAction
) {
	const { type, value } = action

	switch (type) {
		case 'SET_LOCALE':
			return {
				...state,
				locale: value as TLocale
			}
		case 'SET_LOADER':
			return {
				...state,
				loader: value
			}
		case 'SET_UPDATE_MARKET':
			return {
				...state,
				updateMarket: value
			}
		default:
			return state
	}
}

import { FC } from 'react'

import styles from './ButtonSelect.module.sass'
import { IButtonOption } from './button-select.interface'

const ButtonOption: FC<IButtonOption> = ({ onClickOption, option }) => {
	return (
		<button
			type='button'
			onClick={() => onClickOption(option)}
			className={styles.buttonOption}
		>
			{option}
		</button>
	)
}

export default ButtonOption

import React, { FC } from 'react'

import styles from './quizLayout.module.scss'
import { QuizContent } from '@/entities/quiz-content'

import { TQuiz } from '@/widgets/quiz'

interface IProps extends TQuiz {
	image: string
	title?: string
}
export const QuizLayoutOne: FC<IProps> = ({ onNextStep, image, title }) => {
	return (
		<div className={styles.wrapper}>
			<QuizContent title={title} maxWidth='717px' onNextStep={onNextStep} />
			<div
				className='animate__animated animate__backInUp'
				style={{width:'100%'}}
			>
				<img src={image} alt='img' />
			</div>
		</div>
	)
}

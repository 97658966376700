import clsx from 'clsx'
import React, { FC, PropsWithChildren, useState } from 'react'
import { IoIosArrowDown, IoIosArrowForward, IoIosArrowUp } from 'react-icons/io'
import { useNavigate } from 'react-router-dom'

import styles from './Accordion.module.scss'

interface IProps extends PropsWithChildren {
	name: string
	isLink?: boolean
	isActive?: boolean
	link?: string
}

export const Accordion: FC<IProps> = ({
	name,
	isLink,
	isActive,
	children,
	link
}) => {
	const [isOpen, setIsOpen] = useState(false)

	const navigate = useNavigate()

	const onClickHeader = () => {
		if (link) {
			navigate(link)
		} else {
			setIsOpen(prev => !prev)
		}
	}

	return (
		<div className={clsx(styles.wrapper, isOpen && styles.active)}>
			<div
				className={clsx(styles.header, isActive && styles.active)}
				onClick={onClickHeader}
			>
				<div className={styles.name}>{name}</div>
				{!link && (
					<div className={styles.arrow}>
						{isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
					</div>
				)}
				{isLink && <IoIosArrowForward />}
			</div>

			<div className={clsx(styles.body)}>
				<div>{children}</div>
			</div>
		</div>
	)
}

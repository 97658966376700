import { FC } from 'react'
import { Link } from 'react-router-dom'

import styles from './CardSlot.module.scss'
import { IGames } from '@/shared/types/games.types'

export const CardSlot: FC<IGames.IGameData> = ({ id, image_webp, game_name }) => {
	return (
		<Link to={`item/${id}`}>
			<div className={styles.cardSlotItem}>
				<img src={image_webp} alt='' />
				<div className={styles.title}>{game_name}</div>
			</div>
		</Link>
	)
}

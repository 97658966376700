import { ChangeEvent, FC } from 'react'

import styles from './RadioButton.module.scss'

interface IProps {
	title: string
	name: string
	radioRef?: any
	value?: string
	onChange?: any
	onBlur?: any
	checked?: boolean
}

export const RadioButton: FC<IProps> = ({
	title,
	name,
	radioRef,
	value,
	onChange,
	...props
}) => {
	const onHandlerChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange!(e.target.value)
	}

	return (
		<div className={styles.wrapper}>
			<label className={styles.label}>
				<input
					className={styles.input}
					ref={radioRef}
					name={name}
					type='radio'
					value={value}
					onChange={onHandlerChange}
					checked={value === name}
					{...props}
				
				/>
				<span className={styles.check} />
				<span className={styles.title}>{title}</span>
			</label>
		</div>
	)
}

import { FC, useState } from 'react'

import { useBasket } from '../useBasket'

import styles from './MobileBetsBasket.module.scss'
import CouponHeader from '@/entities/sports/bets-basket/coupon-header/mobile-coupon-header'
import EmptyBetsBasket from '@/entities/sports/bets-basket/empty-bets-basket'
import BetTypeSelect from '@/entities/sports/bets-basket/mobile-bet-type-select/BetTypeSelect'
import BetsSettings from '@/entities/sports/bets-basket/mobile-bets-settings/BetsSettings'
import SingleBet from '@/entities/sports/bets-basket/single-bet/mobile-single-bet/SingleBet'
import { useProfileData } from '@/features/profile/api/useProfileData'
import ControlBlock from '@/features/sports/bets-basket/mobile-control-block'
import { TicketJSX } from '@/shared/assets/header/svg'
import CouponTabs from '@/shared/sports/coupon-tabs/mobile-coupon-tabs/CouponTabs'

const MobileBetsBasket: FC = () => {
	const coupon = useBasket()
	const { userData } = useProfileData()

	const [isBetTypeSelectIsOpened, setIsBetTypeSelectIsOpened] = useState(false)
	const [isSettingsOpened, setIsSettingsOpened] = useState(false)

	const [activeTab, setActiveTab] = useState(0)
	const [open, setOpen] = useState(false)

	let coefficient = 0

	coupon.map((el, index) => {
		if (index === 0) coefficient = el.market?.market
		else coefficient = coefficient * el.market?.market
	})
	if (!userData) return null
	return (
		<>
			<div className={styles.couponButtonWidget} onClick={() => setOpen(true)}>
				<TicketJSX />
				{!!coupon.length && (
					<span className={styles.count}>{coupon.length}</span>
				)}
			</div>
			<div className={open ? styles.couponOpen : styles.coupon}>
				<CouponHeader
					isBetTypeSelectIsOpened={isBetTypeSelectIsOpened}
					isSettingsOpened={isSettingsOpened}
					setOpen={setOpen}
				/>
				<div className={styles.content}>
					{isBetTypeSelectIsOpened && (
						<BetTypeSelect
							activeTab={activeTab}
							setActiveTab={setActiveTab}
							setIsBetTypeSelectIsOpened={setIsBetTypeSelectIsOpened}
						/>
					)}

					{isSettingsOpened && (
						<BetsSettings setIsSettingsOpened={setIsSettingsOpened} />
					)}

					{coupon.length ? (
						<>
							<div className={styles.couponBody}>
								<CouponTabs
									activeTab={activeTab}
									setActiveTab={setActiveTab}
									setIsBetTypeSelectIsOpened={setIsBetTypeSelectIsOpened}
								/>
								<div className={styles.betsContainer}>
									{coupon.map((item, index) => (
										<SingleBet {...item} key={index} singl={activeTab === 0} />
									))}
								</div>
							</div>
							<ControlBlock
								setIsSettingsOpened={setIsSettingsOpened}
								singl={activeTab === 0}
								coefficient={coefficient}
							/>
						</>
					) : (
						<div className={styles.couponBody}>
							<EmptyBetsBasket />
						</div>
					)}
				</div>
			</div>
		</>
	)
}

export default MobileBetsBasket

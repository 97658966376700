import cn from 'clsx'
import { FC } from 'react'

import ButtonOption from './ButtonOption'
import styles from './ButtonSelect.module.sass'
import { IButtonSelect } from './button-select.interface'
import { ReactComponent as ArrowIcon } from '@/Assets/svg/arrow-select.svg'
import useClickOutside from '@/shared/hooks/useClickOutside'

const ButtonSelect: FC<IButtonSelect> = ({
	options,
	value,
	onChange,
	className,
	popupClassName,
	placeholder
}) => {
	const { menuRef, isActive, setIsActive } = useClickOutside()

	const onClickButton = () => setIsActive(prevState => !prevState)
	const onClickOption = (option: string) => {
		onChange(option)
		setIsActive(false)
	}

	return (
		<div ref={menuRef} className={cn(styles.container, className)}>
			<div
				onClick={onClickButton}
				className={cn(styles.currentOption, { [styles.opened]: isActive })}
			>
				<p className={cn(!value && styles.placeholder)}>
					{value ? value : placeholder}
				</p>
				<button
					type='button'
					className={cn(styles.arrow, { [styles.rotated]: isActive })}
				>
					<ArrowIcon />
				</button>
			</div>

			{isActive && (
				<div className={cn(styles.popup, popupClassName)}>
					<div>
						{options.map(option => (
							<ButtonOption
								key={option}
								onClickOption={() => onClickOption(option)}
								option={option}
							/>
						))}
					</div>
				</div>
			)}
		</div>
	)
}

export default ButtonSelect

import { Suspense } from 'react'

import not_found from '@/Assets/img/404.webp'
import Layout from '@/shared/ui/layout'
import { Loader } from '@/shared/ui/loader'

interface IProps {
	customBreadcrumbs?: {
		label: any
		path: string
	}[]
}
// Страници не существует
export const NotFound: React.FC<IProps> = ({ customBreadcrumbs }) => (
	// Лоадер во время загрузки страницы
	<Suspense fallback={<Loader />}>
		<Layout
			goBackLink
			title='page_title_not_found'
			description='page_description_not_found'
			titlePage='page_title_not_found'
			descriptionMeta='page_description_not_found'
			customBreadcrumbs={customBreadcrumbs}
			fullWidth={false}
			position='center'
		>
			<div className='NoteFound'>
				<img src={not_found} alt='not_found' />
			</div>
		</Layout>
	</Suspense>
)

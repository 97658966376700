import { useAppSelector } from '../model'
import { TValuesLocality } from '../types/global.types'

import { messages } from '@/i18n/messages'

/**
 * Возвращает функцию, которая в свою очередь возвращает строку текущего языка или null
 * @param data принимает обьект с полями
 * @param fieldName название поля, которое нужно найти. Таким способом
 * Таким способом, если нужно получить title, то в зависимости от языка будет возвращаться enTitle, либо cnTitle
 * */

/**
 * Возвращает текст, который получаем из локали
 * @param message принимает текстовое поле сообщения
 * */

export function useTranslationField() {
	const { locale } = useAppSelector(state => state.defaultReducer)

	const getField = (data: any, fieldName: string): string => {
		if (!data) return ''
		const capitalizedStr =
			fieldName.charAt(0).toUpperCase() + fieldName.slice(1).toLowerCase()

		const currentField = `${locale}${capitalizedStr}`

		return data[currentField] !== undefined
			? data[currentField]
			: data[`${fieldName}_${locale}`]
	}

	const getFieldStatic = (message: TValuesLocality): string => {
		return messages[locale]?.[message] || message || ''
	}

	return { getField, getFieldStatic, locale }
}

import { useEffect, useState } from 'react'

/*
 * @param {string} key - Ключ для хранения значения в localStorage.
 * @return {object} - Объект, содержащий:
 *   - localValue:  Текущее значение, хранимое в localStorage.
 *   - setLocalStorageValue:  Функция для обновления значения в localStorage.
 *   - deleteLocalStorageValue:  Функция для удаления значения из localStorage.
 */
export function useLocalStorage(key: string) {
	// Состояние для хранения значения
	const [value, setValue] = useState(() => {
		// Получаем начальное значение из localStorage
		return localStorage.getItem(key)
	})

	useEffect(() => {
		// Функция-обработчик для обновления значения из localStorage
		const handleStorageChange = () => {
			setValue(localStorage.getItem(key))
		}

		// Подписываемся на событие 'storage'
		window.addEventListener('storage', handleStorageChange)

		return () => {
			// Отписываемся от события 'storage' при размонтировании компонента
			window.removeEventListener('storage', handleStorageChange)
		}
	}, [key])

	// Функция для обновления значения и localStorage
	const setLocalStorageValue = (newValue: string) => {
		setValue(newValue)
		localStorage.setItem(key, newValue)
	}

	// Функция для удаление значения и localStorage
	const deleteLocalStorageValue = () => {
		setValue(null)
		localStorage.removeItem(key)
	}

	return { localValue: value, setLocalStorageValue, deleteLocalStorageValue }
}

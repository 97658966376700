import { FC } from 'react'

import { TQuiz } from '../..'

import styles from './quiz.module.scss'
import { backgroundQuiz_5, image_5, image_5_mobi } from '@/Assets/img/quiz'
import { QuizLayout } from '@/features/quiz/ui/layout'
import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutThree } from '@/shared/ui/quiz-layout/quiz-layout-3'

export const QuizFour: FC<TQuiz> = ({ onFinal }) => {
	const { isMobile } = useIsMobile(768)
	const { getFieldStatic } = useTranslationField()

	return (
		<QuizLayout background={backgroundQuiz_5}>
			<div className={styles.wrapper}>
				<QuizLayoutThree
					image={isMobile ? image_5_mobi : image_5}
					descriptions={[
						getFieldStatic('finalTextVariant2'),
						getFieldStatic('finalText2Variant2')
					]}
					onFinal={onFinal}
				/>
			</div>
		</QuizLayout>
	)
}

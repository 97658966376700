import React, { Component, ErrorInfo, ReactNode } from 'react'

import Layout from '@/shared/ui/layout'
import Error from '@/widgets/error'

interface ErrorBoundaryProps {
	children: ReactNode
}

interface ErrorBoundaryState {
	hasError: boolean
	error?: string
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
	constructor(props: ErrorBoundaryProps) {
		super(props)
		this.state = { hasError: false, error: '' }
	}

	static getDerivedStateFromError(_: Error): ErrorBoundaryState {
		return { hasError: true }
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		console.error('Ошибка:', error, '-----', errorInfo)
		this.setState({ error: String(error) })
	}

	render(): ReactNode {
		if (this.state.hasError) {
			return (
				<Layout fullWidth={false} position=''>
					<Error message={this.state.error!} />
				</Layout>
			)
		}
		return this.props.children
	}
}

export default ErrorBoundary

import clsx from 'clsx'
import { FC, MouseEvent, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useLocation } from 'react-router-dom'

import styles from './Modal.module.scss'
import { TModal, modListModal } from './modal.type'
import { CloseIconSimpleJSX, closeModalIcon } from '@/shared/assets/common/svg'
import useOutsideClick from '@/shared/hooks/useOutsideClick'

interface IPortal {
	children?: React.ReactNode
	disabledPortal?: boolean
}

const Portal: FC<IPortal> = ({ children, disabledPortal }) => {
	const [container] = useState(() => document?.createElement('div'))

	useEffect(() => {
		document.body.appendChild(container)
		return () => {
			document.body.removeChild(container)
		}
	}, [])

	if (disabledPortal) return children

	return ReactDOM.createPortal(children, container)
}

export const Modal: FC<TModal> = ({
	className,
	children,
	isOpen,
	onClose,
	modifers,
	disabledPortal,
	maxWidth,
	background,
	disabledClickOutside,
	title = ''
}) => {
	const { pathname, search } = useLocation()
	const isProfileName = pathname === '/profile' && !search

	const { ref } = useOutsideClick(false)

	modifers?.forEach(item => {
		modListModal[item] = true
	})

	const onKeyDown = (e: globalThis.KeyboardEvent) => {
		if (e.code === 'Escape') {
			onClose()
		}
	}
	useEffect(() => {
		if (isOpen) {
			document.body.style.overflowY = 'hidden'
			document.body.addEventListener('keydown', onKeyDown)
		}

		return () => {
			if (!isProfileName) {
				document.body.style.overflowY = 'auto'
			}
			document.body.removeEventListener('keydown', onKeyDown)
		}
	}, [isOpen, pathname])

	const closeModal = (e: MouseEvent<HTMLDivElement>) => {
		if (disabledClickOutside) return
		e.stopPropagation()
		onClose()
	}
	if (!isOpen) return null

	return (
		<Portal disabledPortal={disabledPortal}>
			<div
				className={clsx(
					styles.modalOverlay,
					modListModal.blur && styles.blur,
					modListModal.absolute && styles.absolute,
					modListModal.inherit && styles.inherit
				)}
				onClick={closeModal}
			>
				<div className={styles.modalContainer}>
					<div
						ref={ref}
						className={clsx(
							styles.modal,
							className,
							modListModal.simple && styles.simple,
							modListModal.zeroOffset && styles.zeroOffset,
							modListModal.tiny && styles.tiny,
							modListModal.small && styles.small,
							modListModal.medium && styles.modalMedium,
							modListModal.deposit && styles.deposit,
							modListModal.light && styles.light
						)}
						style={{
							maxWidth,
							backgroundImage: background ? `url(${background})` : undefined
						}}
						onClick={e => e.stopPropagation()}
					>
						<button type='button' className={styles.close} onClick={onClose}>
							{modListModal.simple && <CloseIconSimpleJSX />}
						</button>
						{title && <div className={styles.title}>{title}</div>}
						<div className={clsx(styles.body)}>{children}</div>
					</div>
				</div>
			</div>
		</Portal>
	)
}

import clsx from 'clsx'
import { Dispatch, FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'

import { MenuElement } from '../menu-element'

import styles from './MobileHeader.module.scss'
import { setLang } from '@/app/store/actions/defaultActions'
import { languages } from '@/app/store/constants'
import { useProfileData } from '@/features/profile/api/useProfileData'
import { messages } from '@/i18n/messages'
import { couponIcon } from '@/shared/assets/common/svg'
import {
	BlogJSX,
	ExpertsJSX,
	GamesJSX,
	SportIconJSX,
	blog,
	games,
	languageIcon,
	menuHeader,
	sport
} from '@/shared/assets/header/svg'
import { useTranslationField } from '@/shared/hooks'
import useOutsideClick from '@/shared/hooks/useOutsideClick'
import { useAppDispatch, useAppSelector } from '@/shared/model'
import { TValuesLocality } from '@/shared/types/global.types'
import { LinkItemMenuProps } from '@/shared/types/global.types'
import MobileBetsBasket from '@/widgets/sports/bets-basket/mobile-bets-basket'

interface IProps {
	showMenu: boolean
	setShowMenu: Dispatch<React.SetStateAction<boolean>>
	dataTabMenu: LinkItemMenuProps[]
}

export const MobileHeaderBottom: FC<IProps> = ({
	showMenu,
	setShowMenu,
	dataTabMenu
}) => {
	const { userData } = useProfileData()
	const { getFieldStatic } = useTranslationField()

	const { locale } = useAppSelector(state => state.defaultReducer)
	const { pathname } = useLocation()

	const menuList = [
		{
			isView: true,
			url: '/sports',
			title: messages[locale].sport_route,
			image: <SportIconJSX />,
			active: 'sports',
			isMain: false,
			onClick: null
		},
		{
			isView: true,
			url: '/games',
			title: messages[locale].games_route,
			image: <GamesJSX />,
			active: 'games',
			isMain: false,
			onClick: null
		},
		{
			isView: userData,
			title: messages[locale].best_slit_name,
			component: <MobileBetsBasket />,
			active: null,
			isMain: false,
			onClick: null
		},
		{
			isView: true,
			url: '/blog',
			title: messages[locale].blog_route,
			image: <BlogJSX />,
			active: 'blog',
			isMain: false,
			onClick: null
		},
		{
			isView: true,
			url: '/experts',
			title: messages[locale].experts_route,
			image: <ExpertsJSX />,
			active: 'experts',
			isMain: false,
			onClick: null
		}
	]

	return (
		<>
			<div className={styles.wrapperHeader}>
				<div className={styles.wrapper}>
					{menuList.map((item, index) => {
						if (!item.isView) return null
						return (
							<MenuElement
								key={index}
								url={item.url}
								title={item.title}
								srcImage={item.image}
								component={item.component}
								active={pathname.includes(item.active!)}
								isMain={item.isMain}
								onClick={item.onClick ? item.onClick : () => {}}
							/>
						)
					})}
				</div>
			</div>
		</>
	)
}

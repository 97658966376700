import clsx from 'clsx'
import { FC } from 'react'
import { Link } from 'react-router-dom'

import { IPropCheckbox } from '../form.interface'

import styles from './Checkbox.module.scss'



export const Checkbox: FC<IPropCheckbox> = ({
	className,
	title,
	link,
	url,
	onChange,
	checked,
	name,
	id,
	defaultChecked,
	disabled,
	modifier
}) => {
	const mod = {
		full: false,
		center: false
	}

	modifier?.forEach(str => {
		mod[str] = true
	})

	return (
		<label
			className={clsx(
				className,
				styles.checkbox,
				disabled && styles.disabled,
				mod.full && styles.fullWidth,
				mod.center && styles.centerPosition
			)}
		>
			<input
				className={styles.checkbox__input}
				type='checkbox'
				disabled={disabled}
				checked={checked}
				onChange={onChange}
				name={name}
				defaultChecked={defaultChecked}
				id={id}
			/>
			<span
				className={clsx(styles.checkbox__check, !checked && styles.disabled)}
			/>

			{title && (
				<div className={styles.checkbox__textBlock}>
					<span className={styles.checkbox__text}>{title}</span>

					{link && (
						<Link
							to={url || `#`}
							className={styles.checkbox__link}
							target='_blank'
						>
							{link}
						</Link>
					)}
				</div>
			)}
		</label>
	)
}

import { TModifiersSelect, TStylesSelector } from '../form.interface'

export const customStyles: (mod: TModifiersSelect) => TStylesSelector = (
	mod: TModifiersSelect
) => {
	const controlBackground = () => {
		if (mod.inherit) return 'inherit'
		return '#fff'
	}
	const singleValueDisplay = () => {
		if (mod.inherit) return '#fff'
		return 'auto'
	}

	const indicatorsContainerDisplay = () => {
		if (mod.inherit) return 'none'
		return 'auto'
	}

	const menuBackground = () => {
		if (mod.inherit) return '#343031'
		return 'auto'
	}

	const valueContainerPadding = () => {
		if (mod.inherit) return '0 5px'
		return '12px 20px'
	}
	const styles = {
		control: (provided, state) => ({
			...provided,
			background: controlBackground(),
			boxShadow: 'none',
			borderRadius: state.menuIsOpen ? '10px 10px 0 0' : '20px',
			cursor: 'pointer',
			border: 'none',
			'&:hover': {
				borderColor: 'transparent !important'
			}
		}),
		singleValue: (provided, state) => ({
			...provided,
			color: singleValueDisplay()
		}),
		indicatorsContainer: () => ({
			display: indicatorsContainerDisplay()
		}),

		container: (provided, state) => ({
			...provided,
			borderRadius: 10,
			width: '100%',
		}),
		valueContainer: (provided, state) => ({
			...provided,
			padding: valueContainerPadding(),
			overflow: 'visible',
			alignContent: 'center',
			fontSize: '13px',
			opacity: '0.5'
		}),
		menu: provided => ({
			...provided,
			maxHeight: '190px',
			zIndex: '100',
			marginTop: 0,
			borderRadius: '0 0 10px 10px',
			boxShadow: 'none',
			top: '98%',
			paddingBottom: '15px',
			paddingTop: '15px',
			background: menuBackground(),
			width: 'max-content'
		}),
		menuList: provided => ({
			...provided,
			padding: '0',
			maxHeight: '99px',
			zIndex: '100',
			overflow: 'auto',
			'&::-webkit-scrollbar': {
				width: '5px',
				height: '5px'
			},
			'&::-webkit-scrollbar-track': {
				borderRadius: '10px',
				backgroundColor: '#bebebe69 '
			},
			'&::-webkit-scrollbar-thumb': {
				borderRadius: '10px',
				background: '#ec620f'
			}
		}),

		placeholder: (provided, state) => ({
			...provided,
			position: 'absolute',
			top: state.hasValue || state.selectProps.inputValue ? -30 : '20%',
			transition: 'top 0.2s, font-size 0.2s',
			fontSize: 13,
			opacity: '0.5'
		})
	} as TStylesSelector
	return styles
}

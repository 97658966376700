import { FC } from 'react'
import { Controller } from 'react-hook-form'

import { Calendar } from '../calendar'
import { IPropCalendar } from '../form.interface'

import { ConnectForm } from './ConnectForm'

export const ConnectCalendar: FC<IPropCalendar> = ({
	registerConfig,
	...props
}) => {
	return (
		<ConnectForm>
			{({ control }) => (
				<Controller
					control={control}
					rules={registerConfig}
					name={props.name}
					render={({ field: { onChange, ref, value, onBlur } }) => {
						
						return (
							<Calendar
								onBlur={onBlur}
								{...props}
								value={value}
								onChange={onChange}
								calendarRef={ref}
								
							/>
						)
					}}
				/>
			)}
		</ConnectForm>
	)
}

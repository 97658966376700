import clsx from 'clsx'
import { FC, useState } from 'react'
import { GoEye, GoEyeClosed } from 'react-icons/go'
import { IoMdClose } from 'react-icons/io'
import InputMask from 'react-input-mask'
import ClipLoader from 'react-spinners/ClipLoader'

import { ConnectedInput } from '../form-connect'
import { IPropInput } from '../form.interface'

import styles from './Input.module.scss'
import { EditIconJSX } from '@/shared/assets/common/svg'

const RegularInput = (props: IPropInput) => (
	<InputMask {...props} mask={props.mask!} />
)

export const Input: FC<IPropInput> = ({
	className,
	containerClassName,
	disabled,
	onChange,
	placeholder,
	type,
	value,
	defaultValue,
	checked,
	id,
	name,
	required,
	errorText,
	connect,
	mask = '',
	maskChar,
	registerConfig,
	icon,
	isPassword,
	iconStart,
	isClear,
	methods,
	style = {},
	isEdit,
	onClickIcon,
	isLoading,
	...inputProps
}) => {
	const [isViewPassword, setViewPassword] = useState(false)

	const InputComponent: FC<IPropInput> = connect ? ConnectedInput : RegularInput

	return (
		<div className={clsx(styles.wrapper, containerClassName)}>
			<InputComponent
				className={clsx(
					className,
					styles.input,
					disabled && styles.disabled,
					iconStart && styles.iconStart,
					errorText && styles.inputError
				)}
				type={isViewPassword ? 'text' : type || 'text'}
				placeholder={placeholder}
				onChange={onChange}
				value={value}
				checked={checked || false}
				id={id}
				name={name}
				disabled={disabled}
				mask={mask}
				required={required || false}
				defaultValue={defaultValue}
				maskChar={maskChar}
				style={style}
				{...(connect
					? {
							registerConfig
					  }
					: {})}
				{...inputProps}
			/>
			{required && <div className={styles.required}></div>}
			{isLoading ? (
				<>
					<div className={clsx(styles.icon, styles.editIcon)}>
						<ClipLoader size={'20px'} color='#A4A4A4' />
					</div>
				</>
			) : (
				<>
					{isClear &&
						!isEdit &&
						!disabled &&
						(value || methods?.watch(name)) && (
							<div
								className={clsx(styles.icon, styles.closeIcon)}
								onClick={() => methods?.setValue(name, '')}
							>
								<IoMdClose />
							</div>
						)}
					{isEdit && !disabled && (value || methods?.watch(name)) && (
						<div
							className={clsx(
								styles.icon,
								styles.editIcon,
								errorText && styles.disabled
							)}
							onClick={() => !errorText && !isLoading && onClickIcon?.()}
						>
							<EditIconJSX />
						</div>
					)}
					{icon && (
						<div className={clsx(styles.icon, iconStart && styles.start)}>
							{icon}
						</div>
					)}
				</>
			)}

			{isPassword && (
				<div
					className={styles.glassWrapper}
					onClick={() => {
						setViewPassword(prev => !prev)
					}}
				>
					{isViewPassword ? <GoEye /> : <GoEyeClosed />}
				</div>
			)}
			{errorText && typeof errorText === 'string' && (
				<div className={styles.error}>
					<span>{errorText}</span>
				</div>
			)}
		</div>
	)
}

import clsx from 'clsx'
import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import styles from './RegisterDemoUser.module.scss'
import { KABAM_POSTBACK } from '@/app/hooks/useForwardPostBack'
import EarnedDailyRewardModal from '@/entities/earned-daily-reward-modal'
import { useProfileActions } from '@/features/profile/api'
import { useTranslationField } from '@/shared/hooks'
import PostBackService from '@/shared/services/postback.service'
import { Button, FormWrapper, Input } from '@/shared/ui/form'
import { Modal } from '@/shared/ui/modal'
import { TModalModifers } from '@/shared/ui/modal/modal.type'

interface IProps {
	isOpen: boolean
}
export const RegisterDemoUser: FC<IProps> = ({ isOpen }) => {
	const [password, setPassword] = useState('')
	const [userId, setUserId] = useState<string>('')
	const navigate = useNavigate()
	const form = useForm()
	const { getFieldStatic } = useTranslationField()
	type TStep = 0 | 1 | 2 | 3
	const { onRegisterUser } = useProfileActions()
	const [step, setStep] = useState<TStep>(0)
	const modifers = ['simple', 'tiny', 'absolute', 'deposit'] as TModalModifers[]

	const onNextStep = async (currentStep?: TStep) => {
		if (currentStep === 1) {
			try {
				const { data } = await onRegisterUser.mutateAsync()
				const kabamInfo = JSON.parse(
					localStorage.getItem(KABAM_POSTBACK) || '{}'
				)
				const { subid, ...rest } = kabamInfo

				if (subid) {
					PostBackService.sendPostBackKabam({ subid }).then(res => {
						localStorage.removeItem(KABAM_POSTBACK)
					})
				}

				setPassword(data.password)
				setUserId(String(data.login_id))

				setStep(currentStep)
			} catch (error) {}
		}
		if (currentStep === 2) {
			setStep(currentStep)
		}
		if (currentStep === 3) {
			setStep(currentStep)
		}
		if (!currentStep) {
			navigate('/')
		}
	}

	const modalComponent = {
		0: (
			<Modal
				isOpen={isOpen}
				onClose={() => onNextStep(1)}
				disabledPortal
				modifers={modifers}
			>
				<div className={styles.wrapper}>
					<div className={styles.title}>{getFieldStatic('excellent')}</div>
					<div className={styles.text}>
						{getFieldStatic('excellent_description')}
					</div>
					<div className={styles.button}>
						<Button
							disabled={onRegisterUser.isLoading}
							onClick={() => onNextStep(1)}
							modifiers={['red']}
						>
							{getFieldStatic('collect')}
						</Button>
					</div>
				</div>
			</Modal>
		),
		1: (
			<Modal
				isOpen={isOpen}
				onClose={() => onNextStep(2)}
				disabledPortal
				modifers={modifers}
				disabledClickOutside
			>
				<div className={clsx(styles.wrapper, styles.two)}>
					<div
						className={styles.title}
						dangerouslySetInnerHTML={{
							__html: getFieldStatic('bonuses_to_account')
						}}
					></div>
					<div className={styles.text}>{getFieldStatic('continue_luck')}</div>
					<div className={styles.form}>
						<FormWrapper methods={form} onSubmit={() => onNextStep(2)}>
							<Input
								value={userId}
								name='user_id'
								placeholder={getFieldStatic('user')}
							/>
							<Input
								value={password}
								name='password'
								placeholder={getFieldStatic('login_pass_new')}
							/>
							<div className={styles.button}>
								<Button modifiers={['red']}> {getFieldStatic('next')}</Button>
							</div>
						</FormWrapper>
					</div>
				</div>
			</Modal>
		),
		2: (
			<EarnedDailyRewardModal
				quizStep={1}
				isOpen={isOpen}
				onClose={() => onNextStep(3)}
			/>
		),
		3: (
			<EarnedDailyRewardModal
				quizStep={2}
				isOpen={isOpen}
				onClose={() => onNextStep()}
			/>
		)
	}

	return (
		<div className={styles.wrapper}>
			{modalComponent?.[step] || modalComponent[0]}
		</div>
	)
}

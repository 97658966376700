import cn from 'clsx'
import { FC } from 'react'

import styles from './BetsSettings.module.sass'
import { IBetsSettings } from './bets-settings.interface'
import RadioBlock from '@/shared/sports/radio-block/RadioBlock'
import Slider from '@/shared/ui/buttons/slider/Slider'

const BetsSettings: FC<IBetsSettings> = ({ setIsSettingsOpened }) => {
	const onClickCancelButton = () => setIsSettingsOpened(false)
	const onClickApplyButton = () => {
		setIsSettingsOpened(false)
	}

	return (
		<div className={styles.betsSettings}>
			<h2>When if odds change</h2>
			<div>
				<RadioBlock
					id='1'
					name='bets-settings'
					text='Accept if odds increase'
					defaultChecked
				/>
				<RadioBlock id='2' name='bets-settings' text='Confirm' />
				<RadioBlock id='3' name='bets-settings' text='Accept any change' />
				<div className={styles.autoMax}>
					<div>
						<h3>AUTOMAX</h3>
						<Slider id='4' />
					</div>
					<p>
						If the amount you enter exceeds the maximum stake limit, your stage
						will be automatically set at the maximum amount
					</p>
				</div>
			</div>
			<div className={styles.buttonsContainer}>
				<div>
					<button
						className={cn(styles.button, 'buttonBlackHoverRedBorderGold')}
						type='button'
						data-content='cancel'
						onClick={onClickCancelButton}
					/>
					<button
						className={cn(styles.button, 'buttonRedHoverBlackBorderGold')}
						type='button'
						data-content='apply'
						onClick={onClickApplyButton}
					/>
				</div>
			</div>
		</div>
	)
}

export default BetsSettings

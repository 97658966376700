import { combineReducers } from 'redux'
import auth from './auth'
import blog from './blog'
import defaultReducer from './default'
import message from './message'
import shop from './shop'
import sport from './sport'

export default combineReducers({
	sport,
	shop,
	auth,
	message,
	blog,
	defaultReducer
})

import { getGameUrl } from '@/shared/api/config/api.config'
import { axiosInstance } from '@/shared/api'
import {
	IGames
} from '@/shared/types/games.types'

const GamesService = {
	async getList(count: number, page: number) {
		return axiosInstance.get<IGames.IGameData[]>(getGameUrl('get_active_games_list'), {
			params: {
				count,
				page
			}
		})
	},
	async getSession(data: IGames.ISessionData) {
		return axiosInstance.post<IGames.IGameSession>(
			getGameUrl(`getsession?game_id=${data.game_id}&lang=${data.lang}`)
		)
	}
}

export default GamesService

import Cookies from 'js-cookie'

import { removeTokensStorage, saveTokensStorage } from './auth.helper'
import { axiosClassic, axiosInstance } from '@/shared/api'
import { getUserUrl } from '@/shared/api/config/api.config'
import { IUsers } from '@/shared/types/user.types'

const AuthService = {
	async register(form: FormData, ref?: string) {
		const response = await axiosClassic.post<string>(
			getUserUrl('/register'),
			form,
			{ params: { ref } }
		)

		return response
	},

	async registerDemo() {
		const response = await axiosClassic.post<IUsers.ResponseDemoRegister>(
			getUserUrl('/demo_user_register')
		)
		if (response.data.access) saveTokensStorage(response.data)

		return response
	},

	async activation(email: string, code: string) {
		const response = await axiosClassic.post<IUsers.IAuthResponse>(
			getUserUrl('/activate'),
			{ email, code }
		)

		if (response.data.access) saveTokensStorage(response.data)

		return response
	},

	async getTokens(email: string, password: string) {
		const response = await axiosClassic.post<IUsers.IAuthResponse>(
			getUserUrl('/access'),
			{ email, password }
		)

		if (response.data.access) saveTokensStorage(response.data)
		AuthService.getMe()

		return response
	},

	async getNewTokens() {
		const refreshToken = localStorage.getItem('refresh')
		try {
			const response = await axiosClassic.get<IUsers.ITokens>(
				getUserUrl('/refresh'),
				{
					headers: { Authorization: `Bearer ${refreshToken}` }
				}
			)

			if (response.data.access) saveTokensStorage(response.data)

			return response
		} catch (error: any) {
			if ([401, 4003].includes(error.response.status)) this.logout()
		}

		const response = await axiosClassic.get<IUsers.ITokens>(
			getUserUrl('/refresh'),
			{
				headers: { Authorization: `Bearer ${refreshToken}` }
			}
		)
	},

	async logout() {
		document.body.removeAttribute('aria-id')
		removeTokensStorage()
	},

	async getMe() {
		const response = await axiosInstance.get<IUsers.UserRating>(
			getUserUrl('/me')
		)
		return response
	},

	async resetPassword(data: IUsers.IResetPasswordForm) {
		const { lang } = data
		const response = await axiosInstance.post<IUsers.UserRating>(
			getUserUrl(`/user/password/reset?lang=${lang}`),
			data.form
		)
		return response
	},

	async sendCodeResetPassword(data: FormData) {
		const response = await axiosInstance.patch<IUsers.UserRating>(
			getUserUrl('/user/password/reset/code'),
			data
		)
		return response
	}
}

export default AuthService

import { FC } from 'react'

interface IProps {
	message: string
}
const IconInfo: FC<IProps> = ({ message }) => (
	<div className='typeAlert'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='61'
			height='61'
			viewBox='0 0 61 61'
			fill='none'
		>
			<path
				d='M30.3264 0C13.6048 0 0 13.6036 0 30.3264C0 47.0492 13.6048 60.6528 30.3264 60.6528C47.0468 60.6528 60.6516 47.0492 60.6528 30.3264C60.6528 13.6048 47.0492 0 30.3264 0ZM30.3264 56.6485C15.8113 56.6485 4.00317 44.8399 4.00317 30.3252C4.00317 15.8105 15.8112 4.00198 30.3264 4.00198C44.8416 4.00198 56.6497 15.8105 56.6497 30.3252C56.6483 44.8391 44.8402 56.6485 30.3264 56.6485Z'
				fill='white'
			/>
			<path
				d='M30.3254 12.5098C28.6188 12.5098 27.2305 13.899 27.2305 15.6047C27.23 16.4313 27.5516 17.2085 28.1357 17.793C28.7202 18.3775 29.4979 18.7001 30.3254 18.7001C32.032 18.7001 33.4203 17.3118 33.4203 15.6051C33.4203 13.898 32.0321 12.5098 30.3254 12.5098L30.3254 12.5098Z'
				fill='white'
			/>
			<path
				d='M30.3237 23.7803C28.8833 23.7803 27.7109 24.9526 27.7109 26.3931V46.179C27.7109 47.6189 28.8833 48.7899 30.3237 48.7899C31.7627 48.7899 32.9351 47.6189 32.936 46.179V26.3931C32.936 24.9526 31.7642 23.7803 30.3237 23.7803Z'
				fill='white'
			/>
		</svg>
		{message}
	</div>
)

const IconSuccess: FC<IProps> = ({ message }) => (
	<div className='typeAlert'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='60'
			height='61'
			viewBox='0 0 60 61'
			fill='none'
		>
			<path
				d='M30 60.6523C13.4584 60.6523 0 47.1951 0 30.6523C0 14.1096 13.4584 0.652344 30 0.652344C46.5404 0.652344 59.9988 14.1096 60 30.6523C60 47.1939 46.5428 60.6523 30 60.6523ZM30 4.61361C15.6412 4.61361 3.96008 16.295 3.96008 30.6535C3.96008 45.012 15.641 56.6934 30 56.6934C44.359 56.6934 56.0399 45.012 56.0399 30.6535C56.0385 16.2959 44.3575 4.61361 30 4.61361Z'
				fill='#7CC726'
			/>
			<path
				d='M26.0574 40.0697C25.5586 40.0697 25.0603 39.856 24.704 39.4998L17.4394 32.2347C16.727 31.5223 16.5849 30.3115 17.2258 29.5995C17.9383 28.745 19.2201 28.6735 20.0036 29.4569L26.058 35.5113H26.129L39.5905 22.0498C40.303 21.3373 41.4423 21.1952 42.2257 21.8362C43.0803 22.5486 43.1518 23.8305 42.3683 24.6139L27.4111 39.5712C27.0548 39.9269 26.5565 40.0695 26.0577 40.0695L26.0574 40.0697Z'
				fill='#7CC726'
				stroke='#7CC726'
				strokeWidth='0.5'
			/>
		</svg>
		{message}
	</div>
)

const IconError: FC<IProps> = ({ message }) => (
	<div className='typeAlert'>
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='61'
			height='62'
			viewBox='0 0 61 62'
			fill='none'
		>
			<path
				d='M30.3264 61.3047C13.6048 61.3047 0 47.701 0 30.9783C0 14.2555 13.6048 0.651855 30.3264 0.651855C47.0468 0.651855 60.6516 14.2555 60.6528 30.9783C60.6528 47.6999 47.0492 61.3047 30.3264 61.3047ZM30.3264 4.65622C15.8113 4.65622 4.00317 16.4647 4.00317 30.9795C4.00317 45.4942 15.8112 57.3027 30.3264 57.3027C44.8416 57.3027 56.6497 45.4942 56.6497 30.9795C56.6483 16.4656 44.8402 4.65622 30.3264 4.65622Z'
				fill='#DC0000'
			/>
			<path
				d='M30.3254 48.7949C28.6188 48.7949 27.2305 47.4057 27.2305 45.7C27.23 44.8734 27.5516 44.0962 28.1357 43.5117C28.7202 42.9272 29.4979 42.6046 30.3254 42.6046C32.032 42.6046 33.4203 43.9929 33.4203 45.6996C33.4203 47.4066 32.0321 48.7949 30.3254 48.7949L30.3254 48.7949Z'
				fill='#DC0000'
			/>
			<path
				d='M30.3237 37.5244C28.8833 37.5244 27.7109 36.3521 27.7109 34.9116V15.1257C27.7109 13.6858 28.8833 12.5148 30.3237 12.5148C31.7627 12.5148 32.9351 13.6858 32.936 15.1257V34.9116C32.936 36.3521 31.7642 37.5244 30.3237 37.5244Z'
				fill='#DC0000'
			/>
		</svg>
		{message}
	</div>
)

export { IconInfo, IconSuccess, IconError }

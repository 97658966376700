import { FC } from 'react'

import styles from './Error.module.scss'
import { useTranslationField } from '@/shared/hooks'


interface IProps {
	message: string
}

const Error: FC<IProps> = () => {
	const { getFieldStatic } = useTranslationField()
	return (
		<div className={styles.wrapper}>
			<div className={styles.image}>
				<img src='' alt='' />
			</div>
			<div className={styles.message}>{getFieldStatic('error_page')}</div>
		</div>
	)
}

export default Error

import websocket from '@giantmachines/redux-websocket'
import { Action, applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkDispatch } from 'redux-thunk'

import rootReducer from './reducers'

// const reduxWebsocketMiddleware = reduxWebsocket();
const websocketMiddleware = websocket({
	prefix: 'REDUX_WEBSOCKET',
	onOpen: (socket: WebSocket) => {
		// @ts-ignore
		window.__socket = socket // eslint-disable-line no-underscore-dangle
	},
	dateSerializer: date => date.toISOString(),
	deserializer: JSON.parse
})

const middleware = [thunk, websocketMiddleware]

export const store = createStore(
	rootReducer,
	composeWithDevTools(applyMiddleware(...middleware))
)

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = ThunkDispatch<RootState, any, Action>


import { QuizLayout } from '@/features/quiz/ui/layout'
import { FC } from 'react'

import { TQuiz } from '../..'

import {
	backgroundQuiz_52,
	background_5_mobi,
	bubble_52,
	image_52
} from '@/Assets/img/quiz'
import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutFour } from '@/shared/ui/quiz-layout/quiz-layoyt-4'

export const QuizFour: FC<TQuiz> = ({ onFinal }) => {
	const { isMobile } = useIsMobile(768)
	const { getFieldStatic } = useTranslationField()

	return (
		<QuizLayout
			background={bubble_52}
			isFinal
			extraImage={isMobile ? background_5_mobi : backgroundQuiz_52}
		>
			<QuizLayoutFour
				image={image_52}
				descriptions={[
					getFieldStatic('finalTextVariant1'),
					getFieldStatic('finalText2Variant1'),
					getFieldStatic('finalText3Variant1')
				]}
				onFinal={onFinal}
			/>
		</QuizLayout>
	)
}

import { AxiosError } from 'axios'

import { getMessageLocality } from './getMessageLocality'

type TError = AxiosError<any>

export interface IResponseSuccess {
	detail: string
}

export interface IResponseFailed {
	loc: [string, any]
	msg: string
	type: string
}

const strFile = (obj?: Record<string, any>): any => {
	if (!obj) return null

	const reduceObject = (data: any): any => {
		return Object.values(data).reduce((acc: any, item) => {
			if (Array.isArray(item)) {
				return acc + item.join('\n') + '\n'
			} else if (typeof item === 'object') {
				return acc + reduceObject(item)
			}

			return acc + item! + '\n'
		}, '')
	}

	return reduceObject(obj)
}

export const getMessageError = (message: TError | Error | null | any) => {
	if (!message) return ''
	const data = message?.response?.data as any

	const messageError = Array.isArray(message)
		? message.join('\n')
		: typeof message === 'string'
		? message
		: typeof data?.detail === 'string'
		? getMessageLocality(data?.detail) || data?.detail
		: Array.isArray(data?.detail)
		? data?.detail.map((item: any) => `${item.loc[1]}: ${item.msg}`).join('\n')
		: data?.file?.join('\n') || strFile(data) || (message?.message as string)
	return messageError
}

import { useEffect, useState } from 'react'

/*
 * @params {number} defaultTime -  Время таймера в секундах по умолчанию (60 секунд).
 * @return {object} - Объект, содержащий:
 *   - timer: Текущее значение таймера в секундах.
 *   - setTimerStart: Функция для запуска и остановки таймера.
 *   - timerStart: Флаг, указывающий на запуск таймера (true - запущен, false - остановлен).
 */
export const useTimerBack = (defaultTime = 60) => {
	const [timer, setTimer] = useState(defaultTime)
	const [timerStart, setTimerStart] = useState(false)

	const onBackTime = () => {
		setTimer(prev => prev - 1)
	}

	useEffect(() => {
		if (timerStart) {
			const intervalId = setTimeout(() => onBackTime(), 1000)

			if (timer <= 0) {
				clearInterval(intervalId)
				setTimerStart(false)
				setTimer(defaultTime)
			}

			return () => clearInterval(intervalId)
		}
	}, [timer, timerStart])

	return { timer, setTimerStart, timerStart }
}

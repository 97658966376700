import { AlertTemplateProps } from 'react-alert'
import { FormattedMessage } from 'react-intl'

import { Button } from '../form'

import { IconInfo } from './AlertSvg'
import { useProfileData } from '@/features/profile/api/useProfileData'
import { messages } from '@/i18n/messages'
import { useTranslationField } from '@/shared/hooks'
import { useAppSelector } from '@/shared/model'
import { TValuesLocality } from '@/shared/types/global.types'

import styles from './AlertTemplate.module.scss'

// Компонент алерта
export const AlertTemplate: (props: AlertTemplateProps) => JSX.Element = ({
	style,
	options,
	message,
	close
}) => {
	const { getFieldStatic } = useTranslationField()
	const { locale } = useAppSelector(state => state.defaultReducer)
	const { userData } = useProfileData()

	return (
		<div style={style} className='alert'>
			{options.type === 'info' ? (
				<IconInfo message={messages[locale].information} />
			) : options.type === 'success' ? (
				<IconInfo message={messages[locale].success} />
			) : options.type === 'error' ? (
				<IconInfo message={messages[locale].error} />
			) : null}
			{typeof message === 'string' && (
				<>
					{getFieldStatic(message as TValuesLocality)}
					{message === 'message_login_success' ? `${userData?.name}!` : ''}
				</>
			)}
			<Button
				classNameContainer={styles.button}
				modifiers={['ultraSmall', 'red']}
				onClick={close}
			>
				{messages[locale].close}
			</Button>
		</div>
	)
}

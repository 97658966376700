import { QuizLayout } from '@/features/quiz/ui/layout'
import React, { FC } from 'react'

import { TQuiz } from '../..'

import { backgroundQuiz_1, image_2, image_2_mobi } from '@/Assets/img/quiz'
import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutOne } from '@/shared/ui/quiz-layout/quiz-layout-1'

export const QuizTwo: FC<TQuiz> = ({ onNextStep }) => {
	const { isMobile } = useIsMobile(768)
	const { getFieldStatic } = useTranslationField()

	return (
		<QuizLayout background={backgroundQuiz_1}>
			<QuizLayoutOne
				image={isMobile ? image_2_mobi : image_2}
				onNextStep={onNextStep}
				title={getFieldStatic('questionTwoVarian1')}
			/>
		</QuizLayout>
	)
}

import { useMutation } from '@tanstack/react-query'

import UserService from '@/shared/services/user.service'
import { IUsers } from '@/shared/types/user.types'

export const useDeposit = () => {
	return useMutation(['get deposit'], (data: IUsers.IGetDeposit) =>
		UserService.getDeposit(data)
	)
}

import { LocalStorageHelper } from '../helper'

import { messages } from '@/i18n/messages'

export type TValuesLocality = keyof typeof messages.en

/**
 * Возвращает текст, который получаем из локали
 * @param message принимает текстовое поле сообщения
 * */

export const getMessageLocality = (message: TValuesLocality): string => {
	const locale = localStorage.getItem('locale') || 'en'

	return messages[locale]?.[message] || ''
}

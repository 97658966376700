import clsx from 'clsx'
import React, { FC } from 'react'

import styles from './quizLayout.module.scss'
import { QuizContent } from '@/entities/quiz-content'
import { TQuiz } from '@/widgets/quiz'

interface IProps extends TQuiz {
	image: string
	title?: string
	descriptions: string[]
}
export const QuizLayoutThree: FC<IProps> = ({
	onNextStep,
	image,
	title,
	onFinal,
	descriptions
}) => {
	return (
		<div className={styles.wrapper}>
			<QuizContent
				maxWidth='796px'
				onFinal={onFinal}
				classNameWrapper={clsx('center', styles.content)}
				classNameButton={styles.button}
			>
				<div className={'text-wrapper'}>
					{descriptions.map(text => (
						<p className={'text'}>{text}</p>
					))}
				</div>
			</QuizContent>
			<div style={{ width: '100%' }}>
				<div className={clsx('animate__animated animate__backInUp')}>
					<img src={image} alt='img' />
				</div>
			</div>
		</div>
	)
}

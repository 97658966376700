import React, { FC } from 'react'

import { backgroundQuiz_1, image_1, image_1_mobi } from '@/Assets/img/quiz'
import { QuizLayout } from '@/features/quiz/ui/layout'
import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutOne } from '@/shared/ui/quiz-layout/quiz-layout-1'

interface IProps {
	onNextStep: () => void
}

export const QuizOne: FC<IProps> = ({ onNextStep }) => {
	const { isMobile } = useIsMobile(768)
	const { getFieldStatic } = useTranslationField()

	return (
		<QuizLayout background={backgroundQuiz_1}>
			<QuizLayoutOne
				image={isMobile ? image_1_mobi : image_1}
				onNextStep={onNextStep}
				title={getFieldStatic('questionOneVarian1')}
			/>
		</QuizLayout>
	)
}

import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import UserService from '@/shared/services/user.service'
import { IHistoryFiltersForm } from '@/shared/types/global.types'

export const usePaymentHistory = (
	historyFilters: IHistoryFiltersForm | null
) => {
	return useQuery(
		['get buys list', historyFilters],
		() => UserService.getBuysList(historyFilters || {}),
		{
			select: ({ data }) => data,
			retry: 0,
			keepPreviousData: true
		}
	)
}

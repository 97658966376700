import { defaultStateType, typeAction } from '../types'

const refresh = localStorage.getItem('refresh')
const access = localStorage.getItem('access')

const defaultState: defaultStateType = refresh
	? {
			access: access,
			refresh: refresh
	  }
	: {
			access: null,
			refresh: null
	  }

export default function auth(state = defaultState, action: typeAction) {
	const { type, value } = action
	switch (type) {
		case 'LOGIN_SUCCESS':
			// @ts-ignore
			localStorage.setItem('refresh', value.refresh)
			return {
				...state,
				access: value.access,
				refresh: value.refresh
			}
		case 'LOGIN_FAIL':
			localStorage.removeItem('refresh')
			return {
				...state,
				access: null,
				refresh: null
			}
		case 'LOGOUT':
			window.location.href = '/'
			return {
				...state,
				access: null,
				refresh: null
			}
		default:
			return state
	}
}

import clsx from 'clsx'
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './MenuElement.module.scss'

interface IProps {
	srcImage: React.ReactNode
	url?: string
	title: string
	isMain?: boolean
	onClick?: () => void
	active?: boolean
	component?: JSX.Element
}

export const MenuElement: FC<IProps> = ({
	title,
	url,
	srcImage,
	isMain,
	onClick = () => {},
	active,
	component
}) => {
	const navigate = useNavigate()
	const onRouter = () => {
		if (url) navigate(url)
		onClick()
	}
	return (
		<div onClick={onRouter}>
			<div className={clsx(styles.wrapper, active && styles.active)}>
				{component ? (
					component
				) : (
					<div className={clsx(styles.image, isMain && styles.mainImage)}>
						{srcImage}
					</div>
				)}
				<span className={styles.title}>{title}</span>
			</div>
		</div>
	)
}

import axios from 'axios'


import { API_URL } from '@/shared/api/config/api.config'
import { getContentType } from '../helpers'

export const axiosClassic = axios.create({
	baseURL: API_URL,
	// headers: getContentType()
})



import { AxiosError } from 'axios'

import { useAppDispatch } from '@/shared/model'
import { setMessage } from '@/app/store/actions/message'

/*
 * @return {object} - Объект, содержащий функции для вывода сообщений:
 *   - addAlertMessage:  Функция для вывода сообщения с заданным типом.
 *   - addErrorAlert:  Функция для вывода ошибки.
 */
export function useAlertMessage() {
	const dispatch = useAppDispatch()

	const addAlertMessage = (
		message: string,
		type: 'success' | 'info' | 'error' = 'success'
	) => {
		dispatch(
			setMessage({
				message: message,
				type: type
			})
		)
	}

	const addErrorAlert = (error: AxiosError<any>) => {
		const message = error?.response?.data?.detail || (error?.message as string)

		if (error?.response?.status === 500) {
			dispatch(
				setMessage({
					message: message || 'Service is temporarily down',
					type: 'error'
				})
			)
		} else {
			const statusCode = error?.response?.status
			dispatch(
				setMessage({
					message: message || `message_${statusCode}`, // Некорректная строка, нужно заменить на реальный перевод
					type: 'error'
				})
			)
		}
	}

	return { addAlertMessage, addErrorAlert }
}

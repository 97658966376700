
export const dateFormatter = (date: Date | string, locale: string) => {
	const dateToFormatting = typeof date === 'string' ? new Date(date) : date

	return dateToFormatting.toLocaleDateString(locale === "en" ? 'en-US' : "zh-CN", {
		month: 'long',
		day: 'numeric',
		year: 'numeric'
	})
}

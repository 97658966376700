import { FC } from 'react'

import { TQuiz } from '../..'

import {
	backgroundQuiz_22,
	bubble_22,
	image_22,
	image_22_mobi
} from '@/Assets/img/quiz'
import { QuizLayout } from '@/features/quiz/ui/layout'
import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'
import { QuizLayoutTwo } from '@/shared/ui/quiz-layout/quiz-layout-2'

export const QuizOne: FC<TQuiz> = ({ onNextStep }) => {
	const { isMobile } = useIsMobile(768)
	const { getFieldStatic } = useTranslationField()

	return (
		<QuizLayout background={backgroundQuiz_22} extraImage={bubble_22}>
			<QuizLayoutTwo
				image={isMobile ? image_22_mobi : image_22}
				onNextStep={onNextStep}
				title={getFieldStatic('questionOneVariant2')}
			/>
		</QuizLayout>
	)
}

import clsx from 'clsx'
import { FC } from 'react'

import { TQuiz } from '../..'

import styles from './quiz.module.scss'
import {
	backgroundQuiz_53,
	background_5_mobi,
	bubble_53,
	image_53
} from '@/Assets/img/quiz'
import { QuizContent } from '@/entities/quiz-content'
import { QuizLayout } from '@/features/quiz/ui/layout'
import { useTranslationField } from '@/shared/hooks'
import { useIsMobile } from '@/shared/hooks'

export const QuizFour: FC<TQuiz> = ({ onFinal }) => {
	const { isMobile } = useIsMobile(768)
	const { getFieldStatic } = useTranslationField()

	return (
		<QuizLayout
			background={bubble_53}
			isFinal
			extraImage={isMobile ? background_5_mobi : backgroundQuiz_53}
		>
			<div className={styles.wrapper}>
				<div
					className={clsx(
						styles.image,
						'animate__animated animate__backInLeft'
					)}
				>
					<img src={image_53} alt='img' />
				</div>

				<QuizContent maxWidth='717px' classNameWrapper='left' onFinal={onFinal}>
					<div
						className={'text-wrapper'}
						style={{ textAlign: 'inherit', maxWidth: '503px' }}
					>
						<p
							className={'text'}
							style={{ textAlign: 'inherit', maxWidth: '400px' }}
						>
							{getFieldStatic('finalTextVariant3')}
						</p>
						<p className={'text'}>{getFieldStatic('finalText2Variant3')}</p>
						<p className={'text'}>{getFieldStatic('finalText3Variant3')}</p>
					</div>
				</QuizContent>
			</div>
		</QuizLayout>
	)
}

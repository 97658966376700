import clsx from 'clsx'
import { CSSProperties, FC, HTMLAttributes } from 'react'
import { FormattedMessage } from 'react-intl'

import styles from './Loader.module.scss'
import { useTranslationField } from '@/shared/hooks'

export interface IProps {
	text?: boolean
	absolute?: boolean
	isTable?: boolean
	isMini?: boolean
	customStyles?: CSSProperties | undefined
}

export const Loader: FC<IProps> = ({
	text,
	absolute,
	isMini,
	isTable,
	customStyles
}) => {
	const { getFieldStatic } = useTranslationField()

	return (
		<div
			style={customStyles}
			className={clsx(
				styles.loader,
				isMini && styles.miniLoader,
				absolute && styles.absolute,
				isTable && styles.tableLoader,
			)}
		>
			<svg viewBox='25 25 50 50'>
				<circle cx='50' cy='50' r='20' />
			</svg>
			{text ? null : (
				<div className={styles.text}>{getFieldStatic('loader')}</div>
			)}
		</div>
	)
}

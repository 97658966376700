import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useState } from 'react'

import GamesService from '@/shared/services/games.service'
import { IGames } from '@/shared/types/games.types'

export const useGameList = (value: number) => {
	const [dataList, setDataList] = useState<IGames.IGameData[] | null>(null)

	const {
		data,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	} = useInfiniteQuery(
		['gameList'],
		({ pageParam = 1 }) => GamesService.getList(value, pageParam),
		{
			getNextPageParam: (lastPage, pages) => {
				if (!lastPage.data.length || lastPage.data.length < value) return null
				return pages.length + 1
			},
			select: data => {
				const res = data.pages.map(item => item.data)
				return { ...data, pages: res }
			}
		}
	)

	useEffect(() => {
		const result =
			data?.pages.reduce((acc, page) => {
				return [...acc, ...page]
			}, [] as IGames.IGameData[]) || []

		if (!isLoading) setDataList(result)
	}, [data?.pages.length])

	return {
		games: dataList,
		isFetching,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		fetchNextPage
	}
}

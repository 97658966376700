import { FC } from 'react'

import { ReactComponent as LoginIcon } from 'src/Assets/svg/login-icon.svg'
import { ReactComponent as RegistrationIcon } from 'src/Assets/svg/user-fill.svg'

import styles from './EmptyBetsBasket.module.sass'
import { useTranslationField } from '@/shared/hooks'

const EmptyBetsBasket: FC = () => {
	const { getFieldStatic } = useTranslationField()
	// const user = useUser()

	return (
		<div className={styles.emptyBetsBasket}>
			<div>
				<p>{getFieldStatic('empty_basket')}</p>
			</div>
			{/*{user ? (*/}
			{/*	<IconTitleButton icon='¥' title='Deposit' subtitle='Balance: 0 CNY' />*/}
			{/*) : (*/}
			{/*	<>*/}
			{/*		<IconTitleButton*/}
			{/*			icon={<LoginIcon />}*/}
			{/*			title='Log in'*/}
			{/*			subtitle='Log in to your account'*/}
			{/*		/>*/}
			{/*		<IconTitleButton*/}
			{/*			icon={<RegistrationIcon />}*/}
			{/*			title='Registration'*/}
			{/*			subtitle='Create a new account'*/}
			{/*		/>*/}
			{/*	</>*/}
			{/*)}*/}
		</div>
	)
}

export default EmptyBetsBasket

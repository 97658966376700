import clsx from 'clsx'
import { FC, useEffect, useState } from 'react'
import { PinInput } from 'react-input-pin-code'

import styles from './FourDigitCode.module.scss'
import { useIsMobile, useTranslationField } from '@/shared/hooks'
import { PinDigit } from '@/shared/ui/form'
import { Logo } from '@/widgets/header/ui'

interface IProps {
	email: string
	isLogo?: boolean
	isError?: boolean
	isSuccess?: boolean
	isLoading?: boolean
	title?: string
	onFinalEnter?: (values: string[]) => void
}

export const FourConfirmCode: FC<IProps> = ({
	email,
	isLogo,
	isError,
	isSuccess,
	onFinalEnter,
	isLoading,
	title
}) => {
	const { isMobile } = useIsMobile()
	const { getFieldStatic } = useTranslationField()
	const [values, setValues] = useState<string[]>(['', '', '', ''])

	useEffect(() => {
		const isFullField = values.every(num => num !== '')
		if (isFullField) {
			onFinalEnter?.(values)
		}
	}, [values])

	return (
		<div className={clsx(styles.wrapper)}>
			{isLogo && (
				<div className={styles.logo}>
					<Logo />
				</div>
			)}
			{title && <div className={styles.title}>{title}</div>}
			<div className={styles.bodyText}>
				<div className={styles.text}>
					{getFieldStatic('sendActivationCode')}
				</div>
				<div className={clsx(styles.text, styles.email)}>{email}</div>
			</div>
			<div className={clsx(styles.text, styles.checkEmail)}>
				{getFieldStatic('checkedEmail')}
			</div>

			<PinDigit
				label={getFieldStatic('login_code')}
				values={values}
				isDisabled={isLoading}
				onChange={val => setValues(val)}
				isError={isError}
				isSuccess={isSuccess}
				placeholder={!isMobile ? '0' : '-'}
			/>

			<div className={styles.info}> {getFieldStatic('code_info')}</div>
		</div>
	)
}
